<div *ngIf="!doctorData.isAuthUser">
  <div
    class="bg-primary h-[150px] w-full flex flex-col justify-center items-center"
  >
    <img
      class="max-w-[200px]"
      width="200"
      height="50"
      ngSrc="/assets/SoowGood-Logo.png"
      alt="logo"
    />
  </div>

  <div class="p-8 flex justify-center items-center flex-col">
    <p>
      You must be
      <a
        class="text-blue-500 font-bold"
        routerLink="/login"
        (click)="dialogRef.close()"
      >
        Login!
      </a>
      to continue!
    </p>
    <p>or</p>
    <p>
      <a
        class="text-blue-500 font-bold"
        routerLink="/signup"
        (click)="dialogRef.close()"
        >Register</a
      >
      a new account.
    </p>
  </div>
</div>

<div *ngIf="!doctorData.userAccess" class="p-8">
  You are an authorized <strong>Doctor</strong>. If you want to consult a doctor
  then you have to login as a patient.
</div>

<div *ngIf="formLoaded && doctorData.isAuthUser && doctorData.userAccess">
  <div class="flex justify-between py-4 px-6 border-b-[1px] items-center">
    <h1 class="dashbord-heading-text">
      <i class="mr-1 text-[18px] fa-solid fa-calendar"></i> Instant Doctor
    </h1>
    <button class="btn-secondary-light" (click)="dialogRef.close()">
      Close
    </button>
  </div>

  <div
    class="w-full px-4 md:px-8 pt-5 pb-6 md:pb-8 overflow-y-auto max-h-screen"
  >
    <mat-stepper #stepper class="mx-auto" [selectedIndex]="activeTab">
      <!-- select booking option -->
      <mat-step>
        <form [formGroup]="bookingForm">
          <ul class="grid grid-row-2 w-full gap-6">
            <li (click)="onStepChange(2, 'self')" *ngIf="userRole == 'patient'">
              <input
                type="radio"
                formControlName="bookMyself"
                id="bookMyself"
                value="bookMyself"
                class="hidden peer"
                required
              />
              <label
                for="bookMyself"
                class="inline-flex items-center justify-between w-full p-3 sm:p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-secondary peer-checked:text-secondary hover:text-gray-600 hover:bg-gray-100"
              >
                <div class="flex items-center gap-3">
                  <i class="fa-solid fa-person md:text-[44px] text-[32px]"></i>
                  <div>
                    <div
                      class="w-full text-[14px] sm:text-[16px] md:text-lg font-semibold"
                    >
                      {{ stepLoading ? "Loading..." : "Book for Self" }}
                    </div>
                    <div class="w-full text-[12px] sm:text-[14px]">
                      If you are patient!
                    </div>
                  </div>
                </div>
                <svg
                  class="w-4 h-4 md:w-5 md:h-5 ml-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </label>
            </li>
            <li (click)="onStepChange(1)">
              <input
                type="radio"
                formControlName="bookOther"
                id="bookOther"
                value="bookOther"
                class="hidden peer"
              />
              <label
                for="bookOther"
                class="inline-flex items-center justify-between w-full p-3 sm:p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-secondary peer-checked:text-secondary hover:text-gray-600 hover:bg-gray-100"
              >
                <div class="flex items-center gap-3">
                  <i
                    class="fa-solid fa-people-robbery md:text-[44px] text-[32px]"
                  ></i>
                  <div>
                    <div
                      class="w-full text-[14px] sm:text-[16px] md:text-lg font-semibold"
                    >
                      Book Someone
                    </div>
                    <div class="w-full text-[12px] sm:text-[14px]">
                      If you are not patient!
                    </div>
                  </div>
                </div>

                <svg
                  class="w-4 h-4 md:w-5 md:h-5 ml-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </label>
            </li>
          </ul>
        </form>
      </mat-step>
      <!-- create new patient or exist patient -->
      <mat-step>
        <div class="flex gap-4">
          <div class="flex items-center px-4 border border-gray-200 rounded">
            <input
              (click)="userExistCheck('exist-user')"
              checked
              id="bordered-radio-2"
              type="radio"
              value=""
              name="bordered-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-0"
            />
            <label
              for="bordered-radio-2"
              class="w-full py-2 ml-2 text-sm font-medium text-gray-900"
            >
              Already Exist
            </label>
          </div>
          <div class="flex items-center px-4 border border-gray-200 rounded">
            <input
              (click)="userExistCheck('new-user')"
              id="bordered-radio-1"
              type="radio"
              value=""
              name="bordered-radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-0"
            />
            <label
              for="bordered-radio-1"
              class="w-full py-2 ml-2 text-sm font-medium text-gray-900"
            >
              New Patient
            </label>
          </div>
        </div>

        <div class="pt-5">
          <div *ngIf="isExistUser">
            <label for="" class="label">Select your patient</label>
            <ng-container>
              <select class="form_input" (input)="getSinglePatientData($event)">
                <option value="">--Select Patient--</option>
                <option *ngFor="let item of userPatientList" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </ng-container>
          </div>

          <div class="pt-5">
            <form [formGroup]="createPatientForm" class="w-full">
              <div class="-mx-3 flex flex-wrap">
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      for="name"
                      class="mb-2 block font-medium text-[14px] text-[#07074D]"
                    >
                      Full Name
                    </label>
                    <input
                      autocomplete="off"
                      formControlName="patientName"
                      type="text"
                      name="patientName"
                      id="patientName"
                      placeholder="Full Name"
                      class="w-full rounded-md border border-[#e0e0e0] bg-white py-1.5 px-3 text-base font-normal text-[14px] text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />

                    <p
                      class="text-red-600 text-sm"
                      *ngIf="
                        formSubmitted &&
                        createPatientForm
                          .get('patientName')
                          ?.hasError('required')
                      "
                    >
                      Please enter patient name.
                    </p>
                  </div>
                </div>

                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      for="age"
                      class="mb-2 block font-medium text-[14px] text-[#07074D]"
                    >
                      Age
                    </label>
                    <input
                      autocomplete="off"
                      maxlength="3"
                      minlength="1"
                      formControlName="age"
                      id="age"
                      placeholder="Age"
                      class="w-full rounded-md border border-[#e0e0e0] bg-white py-1.5 px-3 text-base font-normal text-[14px] text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />

                    <p
                      class="text-red-600 text-sm"
                      *ngIf="
                        formSubmitted &&
                        createPatientForm.get('age')?.hasError('required')
                      "
                    >
                      Please enter patient age.
                    </p>
                    <p
                      class="text-red-600 text-sm"
                      *ngIf="
                        formSubmitted &&
                        createPatientForm.get('age')?.hasError('pattern')
                      "
                    >
                      Age should be a number.
                    </p>
                  </div>
                </div>
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      for="patientMobileNo"
                      class="mb-2 block font-medium text-[14px] text-[#07074D]"
                    >
                      Patient Mobile No
                    </label>
                    <input
                      autocomplete="off"
                      maxlength="11"
                      minlength="11"
                      formControlName="patientMobileNo"
                      id="patientMobileNo"
                      placeholder="Patient Mobile No"
                      class="w-full rounded-md border border-[#e0e0e0] bg-white py-1.5 px-3 text-base font-normal text-[14px] text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />

                    <p
                      class="text-red-600 text-sm"
                      *ngIf="
                        formSubmitted &&
                        createPatientForm
                          .get('patientMobileNo')
                          ?.hasError('required')
                      "
                    >
                      You must enter a valid mobile number!
                    </p>
                    <p
                      class="text-red-600 text-sm"
                      *ngIf="
                        formSubmitted &&
                        createPatientForm
                          .get('patientMobileNo')
                          ?.hasError('pattern')
                      "
                    >
                      Mobile number should have exactly 11 digits and follow the
                      Bangladeshi format!
                    </p>
                  </div>
                </div>
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      for="date"
                      class="mb-2 block font-medium text-[14px] text-[#07074D]"
                    >
                      Select gender
                    </label>
                    <select
                      formControlName="gender"
                      name="gender"
                      id="gender"
                      class="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-3 text-base font-normal text-[14px] text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    >
                      <option value="0" class="font-semibold text-gray-500">
                        Select gender
                      </option>

                      <option *ngFor="let item of genderList" [value]="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                    <p
                      class="text-[14px] text-red-600"
                      *ngIf="
                        formSubmitted &&
                        (createPatientForm.get('gender')?.value == null ||
                          createPatientForm.get('bloodGroup')?.value == '0')
                      "
                    >
                      Please select gender!
                    </p>
                  </div>
                </div>
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      for="bloodGroup"
                      class="mb-2 block font-medium text-[14px] text-[#07074D]"
                    >
                      Select blood group
                    </label>
                    <select
                      formControlName="bloodGroup"
                      name="bloodGroup"
                      id="bloodGroup"
                      class="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-3 text-base font-normal text-[14px] text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    >
                      <option value="0" class="font-semibold text-gray-500">
                        Select blood group
                      </option>
                      <option value="A+" class="font-semibold text-gray-500">
                        A+
                      </option>
                      <option value="A-" class="font-semibold text-gray-500">
                        A-
                      </option>
                      <option value="B+" class="font-semibold text-gray-500">
                        B+
                      </option>
                      <option value="B-" class="font-semibold text-gray-500">
                        B-
                      </option>
                      <option value="O+" class="font-semibold text-gray-500">
                        O+
                      </option>
                      <option value="O-" class="font-semibold text-gray-500">
                        O-
                      </option>
                      <option value="AB+" class="font-semibold text-gray-500">
                        AB+
                      </option>
                      <option value="AB-" class="font-semibold text-gray-500">
                        AB-
                      </option>
                      <option
                        value="others"
                        class="font-semibold text-gray-500"
                      >
                        others
                      </option>
                    </select>
                    <p
                      class="text-[14px] text-red-600"
                      *ngIf="
                        formSubmitted &&
                        (createPatientForm.get('bloodGroup')?.value == null ||
                          createPatientForm.get('bloodGroup')?.value == '0')
                      "
                    >
                      Please select blood group!
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="flex gap-5">
          <button class="btn-secondary py-2 mt-6" (click)="onStepChange(0)">
            Back
          </button>
          <button
            class="btn-secondary group bg-primary py-2 mt-6"
            (click)="createNewPatient()"
          >
            <div
              *ngIf="btnLoader == false"
              class="flex items-center gap-1 text-white group-hover:text-primary"
            >
              <span>Continue</span>
            </div>
            <span
              *ngIf="btnLoader == true"
              class="loading loading-dots loading-md"
            ></span>
          </button>
        </div>
      </mat-step>

      <mat-step>
        <app-booking-review
          [bookingInfo]="bookingInfo"
          (gotToBack)="onStepChange(0)"
        ></app-booking-review>
      </mat-step>
    </mat-stepper>
  </div>
</div>
